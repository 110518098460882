'use client';

import { useEffect, useState } from 'react';
import Image from 'next/image';
import clsx from 'clsx';
import { Icon, Typography } from '@/components';
import { NavItem } from './NavItem';
// import { MobileStickers } from './MobileStickers';
import useScrolled from '@/hooks/useScrolled';
import { Cross, MainLogo, Linkedin, Hamburger, X } from '@/assets';
import { NAV_ITEMS } from '@/constants/NavItems';

const SOCIAL_LINKS = [
  {
    component: Linkedin,
    href: 'https://linkedin.com/company/splash-technology-uk',
    name: 'linkedin',
  },
  {
    component: X,
    href: 'https://twitter.com/splashtech',
    name: 'twitter',
  },
];

const Navbar = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isLinkClicked, setIsLinkClicked] = useState(false);
  const { isScrollingDown, isScrolled } = useScrolled();

  const toggleMobileMenu = () => {
    if (isMobileMenuOpen) {
      setIsLinkClicked(false);
    }
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleClickLink = () => {
    setIsLinkClicked(true);
    setMobileMenuOpen(false);
  };

  useEffect(() => {
    // Prevent scrolling when navbar menu is expanded
    if (isMobileMenuOpen) {
      document.body.style.opacity = 'hidden';
    }

    const mediaQuery = window.matchMedia('(min-width: 769px)');
    const handleMediaChange = (event: MediaQueryListEvent) => {
      if (event.matches) {
        setMobileMenuOpen(false);
      }
    };
    mediaQuery.addEventListener('change', handleMediaChange);

    return () => {
      document.body.style.overflow = 'unset';
      mediaQuery.removeEventListener('change', handleMediaChange);
    };
  }, [isMobileMenuOpen, isLinkClicked]);

  return (
    <nav
      className={clsx(
        'text-neutral-0 fixed top-0 left-0 right-0 z-[100] flex-col justify-between transition-all duration-200 ease-in-out hidden md:flex overflow-hidden',
        {
          'bg-neutral-900': !isScrolled,
          'bg-neutral-900-transparent backdrop-blur-[19px]': isScrolled,
          'h-[104px]': !isScrolled && !isMobileMenuOpen,
          'h-[96px]': isScrolled && !isMobileMenuOpen,
          'h-screen': isMobileMenuOpen,
          '-translate-y-full': isScrollingDown,
        }
      )}>
      <div>
        {/* <MobileStickers /> */}

        <div
          className={clsx('flex justify-between items-start px-6 transition-all duration-200 ease-in-out', {
            'pb-4 pt-14': !isScrolled,
            'py-8': isScrolled,
          })}>
          <div className='w-8' />
          <div className='flex justify-center  h-8'>
            <Image src={MainLogo} width={107} height={32} alt='Splash Tech' />
          </div>
          <button type='submit' onClick={toggleMobileMenu} aria-label='Navbar menu'>
            <Icon
              Component={isMobileMenuOpen ? Cross : Hamburger}
              className='relative md:-top-[5px] fill-neutral-0 cursor-pointer transition-all duration-200 ease-in-out hover:fill-secondary'
            />
          </button>
        </div>

        <ul className='pt-12 flex flex-col gap-8 items-center'>
          {[...NAV_ITEMS.LEFT, ...NAV_ITEMS.RIGHT].map(({ href, label }) => (
            <NavItem onClick={handleClickLink} key={label} href={href} label={label} />
          ))}
        </ul>
      </div>

      <div className='border-solid border-t-[1px] h-[154px] px-6 flex flex-col justify-center items-center gap-8'>
        <Typography tag='p' size='bodySmall' className='text-sm' weight='normal'>
          The Company is licensed and regulated by the Malta Gaming Authority, hereinafter the “MGA”, by virtue of
          license number MGA/XXX/XXX/XXX (issued xx/02/2025), issued in the name of the reporting entity Splash
          Technology International Limited, a company registered under the laws of Malta with company number C109360,
          having its registered office situated at 4TH FLOOR KINGSWAY PALACE, TRIQ IR- REPUBBLIKA, V ALLETTA, VLT 1115,
          Malta to provide Jackpot creation and management systems.
        </Typography>
      </div>

      <div className='h-[154px] px-6 flex flex-col justify-center items-center gap-8'>
        <div className='flex justify-center gap-8'>
          {SOCIAL_LINKS.map(({ href, component, name }) => (
            <a key={href} href={href} target='_blank' aria-label={name} rel='noreferrer'>
              <Icon
                Component={component}
                className='fill-neutral-0 cursor-pointer transition-all duration-200 ease-in-out hover:fill-secondary'
              />
            </a>
          ))}
        </div>
        <Typography tag='p' size='bodySmall' className='text-sm' weight='normal'>
          © 2025 all rights reserved
        </Typography>
      </div>
    </nav>
  );
};

export default Navbar;
